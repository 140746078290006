body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.react-grid-item:not(.react-grid-placeholder) {
  border: 1px solid #ccc;
}

.react-grid-item.react-grid-placeholder {
  background: #333 !important;
  opacity: 0.1 !important;
}

.htInvalid {
  color: #ff0000;
}

#atlwdg-trigger {
  z-index: 10000;
}

.selectable-selectbox {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
}

.cell-grid-item.selecting {
  background: rgb(255, 160, 47) !important;
}

.button-back.disabled {
  display: none !important;
  visibility: hidden !important;
}

.button-forward.disabled {
  display: none !important;
  visibility: hidden !important;
}

/* SELECT WITH ICONS */

.iconOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 1rem;
}
.iconOptionFocused {
  background-color: rgba(0, 126, 255, 0.1);
}
.iconOptionSelected {
  font-weight: bold;
}

.optionIcon {
  height: 25px;
  width: auto;
  flex: 0 0 auto;
}

.optionLabel {
  flex: 1 1 auto;
}
